import { navigate } from 'gatsby';
import * as React from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import InfoCard from '../../InfoCard/InfoCard';
import List from '../../List/List';
import ProcessSlider from '../../ProcessSlider/ProcessSlider';
import BlockHeader from '../../UI/BlockHeader/BlockHeader';
import Icon from '../../UI/Icon/Icon';
import PageIntro from '../../UI/PageIntro/PageIntro';

import './AboutUsPage.sass';
import { ReviewCard } from '../../UI/ReviewCard/ReviewCard';
import HomePageReviews from '../HomePage/HomePageReviews/HomePageReviews';

const techStack = [
  {
    name : 'Swift',
    logo : 'swift',
  },
  {
    name : 'NodeJs',
    logo : 'node-js',
  },
  {
    name : 'MongoDB',
    logo : 'mongodb',
  },
  {
    name : 'HTML5, CSS3, JS',
    logo : 'web',
  },
  {
    name : 'Kotlin',
    logo : 'kotlin',
  },
  {
    name : 'Redis',
    logo : 'redis',
  },
  {
    name : 'GraphQL',
    logo : 'graphql',
  },
  {
    name : 'Angular',
    logo : 'angular',
  },
  {
    name : 'Apollo',
    logo : 'apollo',
  },
  {
    name : 'React',
    logo : 'react',
  },
  {
    name : 'PostgreSQL',
    logo : 'postgresql',
  },
  {
    name : 'WebSockets',
    logo : 'web-sockets',
  },
];

class AboutUsPage extends React.Component {

  onServiceClick = (link) => () => {
    navigate(link);
  };

  render() {
    const lottieOptions = {
      loop             : true,
      autoplay         : true,
      rendererSettings : {
        preserveAspectRatio : 'xMidYMid slice',
      },
    };

    return (
      <div className='about-page'>
        <Header/>
        <PageIntro title="<span class='underlined'>About Us:</span><br/>Youshido as a team"
                   description='At our core, we’re a team of problem solvers, challenge-seekers, brainstormers, and researchers.<br/>Working with Youshido means partnering with people who genuinely care about how you present yourself digitally.<br/>We relentlessly search for the most innovative, original approach for your brand to connect with your audience.'
                   heading='heading-about'
        />
        <div className='about__intro-image'>
          <img src='../../../images/team.jpg' alt='Illustration: Youshido team'
               title='Youshido team'/>
        </div>
        <div className='about__intro max-width'>
          <h2>We infuse efficient and eloquent design and architecture into your digital products.</h2>
          <div className='about__intro-text'>
            Have you ever used a product and been pleasantly surprised by its simplicity and amazed that no one has thought of it before now? That’s exactly what we strive to create. Whether you want to save the planet or start a daycare, we design and bring to life meaningful ways of connecting with your audience.
          </div>
        </div>
        <div className='about__features'>
          <div className='max-width'>
            <Icon name='heading-vision' className='heading' raw/>
            <div className='about__features-item'>
              <BlockHeader
                title="It's all about quality."
                orientation='vertical'
                subtitle={`Instead of waiting around for inspiration to strike from above, we dive headfirst into our work with determination to exceed our clients’ expectations while thinking outside of the box. Our creativity feeds on our hyper-intense work.`}
              >
              </BlockHeader>
              <div className='about__features-animation'>
                <video width="100%" height="100%" loop autoPlay muted playsInline>
                  <source src="../../../images/patefon.mp4" type="video/mp4"/>
                </video>
              </div>
            </div>
          </div>
        </div>
        <div className='about__process'>
          <div className='max-width'>
            <Icon name='heading-process' className='heading' raw/>
            <ProcessSlider isWhite />
          </div>
        </div>
        <div className='about__inspiration'>
          <div className='max-width'>
            <Icon name='heading-inspiration'/>
            <Icon name='icon-double-quotes' raw/>
            <ReviewCard
              data={{
                message  : 'To achieve great things, two things are needed: a plan and not quite enough time.',
                name     : 'Leonard Bernstein',
                avatar   : '/images/leonard-bernstein.jpg',
                position : 'American composer, conductor, music lecturer and pianist',
              }}
            />
          </div>
        </div>
        <div className='about__technologies max-width'>
          <BlockHeader
            title='Our innovative technology'
            subtitle="The only rule we always have is to use the right tool for a job. We love GraphQL APIs for their versatility and NodeJS and TypeScript for their efficiency and development speed. To ensure we are always offering the best of the best to our clients, we constantly explore and test the most cutting-edge web design technologies."
          />
          <List data={techStack} withHint>
            <Icon name='heading-tech' className='heading' raw/>
          </List>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default AboutUsPage;
