import React, { Component } from 'react'
import PageWrapper from '../components/PageWrapper/PageWrapper'
import AboutUsPage from '../components/Pages/AboutUsPage/AboutUsPage'
import SEO from '../components/seo'

class AboutUs extends Component {
  render() {
    return (
      <PageWrapper>
        <SEO
          title='About Us'
          description='At our core, we’re a team of problem solvers, challenge-seekers, brainstormers, and researchers. Working with Youshido means partnering with people who genuinely care about how you present yourself digitally. We relentlessly search for the most innovative, original approach for your brand to connect with your audience.'
        />
        <AboutUsPage/>
      </PageWrapper>
    )
  }
}

export default AboutUs
