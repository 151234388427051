import * as React from 'react';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import { Navigation, Pagination, Swiper } from 'swiper/js/swiper.esm';
import { PROCESS_STEPS } from '../../constants/constants';

import './ProcessSlider.sass';

const swiperParams: any = {
  slidesPerView  : 1,
  centeredSlides : true,
  autoHeight     : true,
  spaceBetween   : 10,
  Swiper,
  modules        : [Pagination, Navigation],
  pagination     : {
    el        : '.swiper-pagination',
    type      : 'bullets',
    clickable : true,
  },
  navigation     : {
    nextEl : '.swiper-button-next',
    prevEl : '.swiper-button-prev',
  },
};

type Props = {
  isWhite?: boolean
}

class ProcessSlider extends React.Component<Props, unknown> {

  state = {
    rerenderKey : 1,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ rerenderKey : 2 });
    }, 500)
  }

  renderStep = (step, i) => {
    const { isWhite } = this.props;
    let imgUrl = step.image;
    if (isWhite) {
      imgUrl = imgUrl.replace(/\.png/, '-black.png');
    }
    return (
      <div className='process__slider-step' key={i}>
        <div>
          <div className='process__step-title'>
            <span className='underlined'>{i + 1}. {step.title}</span>
          </div>
          <div className='process__step-description'>{step.description}</div>
        </div>
        <div className='process__step-image'>
          <img src={`../../../../images/process/${imgUrl}`}
               alt={`Illustration: ${step.title} step`}/>
        </div>
      </div>
    );
  };

  render() {
    const { isWhite } = this.props;
    return (
      <ReactIdSwiperCustom
        {...swiperParams}
        key={this.state.rerenderKey}
        shouldSwiperUpdate
        containerClass={`swiper-container process-slider ${isWhite ? ' -white' : ''}`}
      >
        {PROCESS_STEPS.map(this.renderStep)}
      </ReactIdSwiperCustom>
    );
  }
}

export default ProcessSlider;
