import * as React from 'react';
import ResolutionContext from '../../context/ResolutionContext';
import Icon from '../UI/Icon/Icon';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import { Pagination, Swiper } from 'swiper/js/swiper.esm';

const swiperParams: any = {
  slidesPerView  : 1,
  centeredSlides : true,
  spaceBetween   : 0,
  Swiper,
  modules        : [Pagination],
  pagination     : {
    el        : '.swiper-pagination',
    type      : 'bullets',
    clickable : true,
  },
};

import './List.sass';

class List extends React.Component<{
  data: { name: string, logo: string }[]
  withHint?: boolean
  children?: any
}> {

  renderItem = (company) => {
    return (
      <div className='list__item' key={company.name}>
        <Icon name={company.logo} raw/>
        {this.props.withHint && (
          <div className='list__item-name'>{company.name}</div>
        )}
      </div>
    );
  };

  renderStep = (data, i) => {
    return (
      <div className='list' key={i}>
        {data.map(this.renderItem)}
      </div>
    );
  };

  getSliderData = () => {
    let temp = [];
    const { data } = this.props;
    return data.reduce((acc, item, index) => {
      if (!((index + 1) % 4)) {
        temp.push(item);
        const newValue = [
          ...acc,
          temp,
        ];
        temp = [];
        return newValue;
      } else {
        temp.push(item);
        return acc;
      }
    }, []);
  };

  render() {
    const { data, children } = this.props;
    const sliderData = this.getSliderData();

    return <ResolutionContext.Consumer>
      {context => context.isMobile ? (
        <ReactIdSwiperCustom {...swiperParams}>
          {sliderData.map(this.renderStep)}
        </ReactIdSwiperCustom>
      ) : (
        <div className='list'>
          {children}
          {data.map(this.renderItem)}
        </div>
      )}
    </ResolutionContext.Consumer>;
  }
}

export default List;
