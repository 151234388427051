import * as React from 'react';
import cn from 'classnames';

import './ReviewCard.sass';

type Props = {
  data: {
    message: React.ReactNode
    name: string
    position?: string
    avatar?: string
    companyLogo?: string
    companyName?: string
  }
  className?: string
}

export const ReviewCard = (props: Props) => {
  const { message, name, position, avatar, companyLogo, companyName } = props.data;
  const { className } = props;

  return (
    <div className={cn('home-page__reviews-item', className)}>
      <div className='home-page__reviews-item-message'>
        {message}
      </div>
      <div className='title-divider'/>
      <div className='home-page__reviews-item-footer'>
        <div className='home-page__reviews-item-author'>
          <div
            className='home-page__reviews-item-avatar'
            style={{ backgroundImage : `url("${avatar}")` }}
          />
          <div>
            <div className='home-page__reviews-item-name'>
              {name}
            </div>
            <div className='home-page__reviews-item-position'>
              {position}
            </div>
          </div>
        </div>
        {!!companyLogo && (
          <div className='home-page__reviews-item-logo'>
            <img src={`../../../../images/${companyLogo}`} alt={`Logo: ${companyName}`}/>
          </div>
        )}
      </div>
    </div>
  );
};
